import { getSapFirmDetailsList } from "@/api/contractReport";
import RemoteSelect from "@/pages/common/remoteSelect";
import { allSupplier } from "@/utils/constant";
import { Button, Card, Col, Collapse, DatePicker, Divider, Form, Input, message, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { getProductDetailList } from "@/api/contractReport";
import { getFirmDetailsList } from "@/api/constant";
import { getCode, noRepeat } from "@/utils/common";

interface Props {
  onSearch?: (arg0: any) => void;
  userPermissions: any;
  switcher: any;
  userDetails: any;
}
const SearchFilter: React.FC<Props> = (props) => {
  const { onSearch, userPermissions, switcher, userDetails } = props;
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isSelectFetching, setIsSelectFetching] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [expandCollapseText, setExpandCollapseText] = useState("展开高级筛选");
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<any>({});
  const [form] = useForm();
  const { Panel } = Collapse;

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  const handelExpandCollapse = useCallback(() => {
    setIsClick(!isClick);
    setIsExpand(!isExpand);
    setExpandCollapseText(isExpand ? "展开高级筛选" : "收起高级筛选");
  }, [isClick, isExpand]);

  // 外部 group 用户查询公司（集团公司）
  const fetchCustomerOptions_External = useCallback((controlIdentifier: any) => {
    getFirmDetailsList({ accountNumber: controlIdentifier })
      .then((res: any) => {
        if (res.data && res.data.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          const currentCustomerOption = options.find((val: any) => val.key === switcher.customerCode);
          setCustomerOptions(options);
          form.setFieldsValue({
            customerCode: currentCustomerOption?.value
          });
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [form, switcher]);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getSapFirmDetailsList({
        customerName: value || "",
        virtualFlag: "1"
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    []
  );

  // 获取产品名称选项
  const fetchProductNameOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSearchLoading(true);
      getProductDetailList({
        salesOrgs: searchValue.orgCode || "",
        productName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
            callback([]);
          } else if (res.data?.products && currentValue === value) {
            let products: any = [];
            if (res.data.products?.length === 0) {
              message.error("No Results Found");
            }
            if (userPermissions.CNExternalUsers) {
              products = res.data.products.map((val: any) => {
                return {
                  label: val.productName,
                  value: val.productName,
                  key: val.productCode
                };
              });
              products = noRepeat(products);
            } else if (userPermissions.CNInternalUsers) {
              products = res.data.products.map((item: any) => {
                return {
                  label: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                  value: `${item.productName}(${item.productCode.replace(/\b(0+)/gi, "")})`,
                  key: item.productCode.replace(/\b(0+)/gi, "")
                };
              });
            }
            callback(products);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSearchLoading(false);
        });
    },
    [userPermissions.CNExternalUsers, userPermissions.CNInternalUsers, searchValue.orgCode]
  );

  const formValueChange = useCallback(
    (changeValue: any, allValues: any) => {
      if (changeValue.dce !== undefined) {
        const value = changeValue.dce.replace(/[^\-?\d.]/g, "");
        form.setFieldsValue({ dce: value });
        allValues.dce = value;
      }
      setSearchValue(allValues);
    },
    [form]
  );

  const onFinish = useCallback((values: any) => {
    if (!onSearch) return;
    const tempValues = {
      orgCode: values.orgCode === "All" ? "" : values.orgCode,
      divisionCode: switcher.divisionCode === "All" ? "" : switcher.divisionCode,
      startDate: values.dateRange ? values.dateRange[0].format("YYYY-MM-DD") : "",
      endDate: values.dateRange ? values.dateRange[1].format("YYYY-MM-DD") : "",
      customerCode: getCode(values.customerCode),
      contractNo: values.contractNo,
      productCode: getCode(values.productCode)
    }
    // 外部用户如何没有选择买方，则使用switcher中的customerCode进行查询
    if (userPermissions.CNExternalUsers && !tempValues.customerCode) {
      tempValues.customerCode = switcher.customerCode;
    }
    setSearchValue(tempValues);
    onSearch(tempValues);
  }, [onSearch, switcher.divisionCode, switcher.customerCode, userPermissions.CNExternalUsers]);

  // // 清空
  // const resetSearch = useCallback(() => {
  //   form.resetFields();
  //   let tempValues = {
  //     orgCode: switcher.orgCode,
  //     customerCode: switcher.customerCode || null
  //   };

  //   setSearchValue(tempValues);
  //   form.setFieldsValue({
  //     orgCode: switcher.orgCode && switcher.orgCode !== "All" ? switcher.orgCode : ""
  //   });
  //   if (userPermissions.CNExternalUsers) {
  //     if (!userDetails?.isGroup) {
  //       form.setFieldsValue({
  //         customerCode: switcher.customerName + "(" + switcher.customerCode + ")"
  //       });
  //     } else {
  //       if (switcher.customerCode?.split(",").length === 1) {
  //         form.setFieldsValue({
  //           customerCode: switcher.customerName + "(" + switcher.customerCode + ")"
  //         });
  //       } else {
  //         form.setFieldsValue({ customerCode: "" });
  //       }
  //     }
  //   }
  //   form.submit();

  // }, [form, switcher, userDetails, userPermissions]);

  const resetSearch = useCallback(() => {
    form.resetFields();
  
    // 设置临时搜索值
    const tempValues = {
      orgCode: switcher.orgCode,
      customerCode: switcher.customerCode || null
    };
    setSearchValue(tempValues);
  
    // 设置 orgCode 的值
    const orgCodeValue = switcher.orgCode && switcher.orgCode !== "All" ? switcher.orgCode : "";
    form.setFieldsValue({ orgCode: orgCodeValue });
  
    // 设置 customerCode 的值
    if (userPermissions.CNExternalUsers) {
      const getCustomerCodeValue = () => {
        const code = `${switcher.customerName}(${switcher.customerCode})`;
        if (!userDetails?.isGroup || switcher.customerCode?.split(",").length === 1) {
          return code;
        }
        return null;
      };
  
      form.setFieldsValue({ customerCode: getCustomerCodeValue() });
    }
  
    form.submit();
  }, [form, switcher, userDetails, userPermissions]);
  

  useEffect(() => {
    if (switcher) {
      form.setFieldsValue({ orgCode: switcher.orgCode && switcher.orgCode !== "All" ? switcher.orgCode : "" });
      if (userPermissions.CNExternalUsers) {
        // 判断是否为集团用户
        const userDataCheck = userDetails && Object.keys(userDetails).length && userDetails.entitlements;
        if (userDataCheck) {
          const { entitlements } = userDetails;
          const {
            otherControls: { companyAccountType },
            controlIdentifier
          } = entitlements[0];
          if (companyAccountType === "GROUP") {
            form.setFieldsValue({ customerCode: null });
            fetchCustomerOptions_External(controlIdentifier);
          } else {
            form.setFieldsValue({ customerCode: switcher.customerName + "(" + switcher.customerCode + ")" });
          }
        }
      }
      form.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, switcher.customerCode, switcher.divisionCode, switcher.orgCode, userPermissions.CNExternalUsers, userDetails?.entitlements]);

  return (
    <div className="search-filter">
      <Card
        style={{ boxShadow: "0 5px 9px #dedbdb" }}
        bodyStyle={{ paddingTop: 0 }}
        title="查询条件"
        headStyle={{
          backgroundColor: "white",
          borderBottom: "none"
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={formValueChange}
        >
          <Row gutter={{ md: 16, xs: 8 }}>
            <Col md={5} xs={24}>
              <Form.Item name="orgCode">
                <Select
                  placeholder="请选择卖方公司"
                  options={allSupplier()}
                />
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name="dateRange">
                <DatePicker.RangePicker dropdownClassName="range-picker-drop-down-mobile" placeholder={["开始日期", "截止日期"]} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            {userPermissions.CNInternalUsers && (
              <Col md={5} xs={24}>
                <Form.Item
                  name="customerCode"
                >
                  <RemoteSelect
                    placeholder="请输入买方名称查询"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSelectFetching}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {userPermissions.CNExternalUsers && userDetails?.isGroup && (
              <Col md={5} xs={24}>
                <Form.Item name="customerCode">
                  <Select
                    showSearch
                    placeholder="请选择公司名称"
                    options={customerOptions}
                    optionFilterProp="label"
                    filterOption={onFilterOption}
                    style={{ width: "100%" }}
                    disabled={switcher.customerCode?.split(",").length <= 1}
                  />
                </Form.Item>
              </Col>
            )}
            {userPermissions.CNExternalUsers && !userDetails?.isGroup && (
              <Col md={5} xs={24}>
                <Form.Item name="customerCode">
                  <Input disabled />
                </Form.Item>
              </Col>
            )}
            <Col md={2} xs={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                    className="submit-btn"
                    disabled={isExpand}
                  >
                    查询
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          {/* 展开筛选 */}
          <Collapse
            style={{ marginTop: -25 }}
            ghost
            expandIconPosition="right"
            onChange={handelExpandCollapse}
            collapsible="header"
          >
            <Panel header={<span style={{ color: "#007ed9" }}>{expandCollapseText}</span>} key="1" forceRender={true}>
              <Divider dashed={true} style={{ width: "101%", margin: "-10px 0 13px -14px" }} />
              <div className="expand-collapse">
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col md={5} xs={24}>
                    <Form.Item label="合同编号" name="contractNo">
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={5} xs={24}>
                    <Form.Item label="产品名称" name="productCode">
                      <RemoteSelect
                        placeholder="请输入产品名称查询"
                        fetchOptions={fetchProductNameOptions}
                        loading={isSearchLoading}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="advance-btn-col">
                    <Form.Item>
                      <div className="advance-btn">
                        <Button
                          style={{ marginRight: 10, backgroundColor: "#5c5c5c" }}
                          type="primary"
                          onClick={resetSearch}
                        >
                          清空
                        </Button>
                        <Button type="primary" htmlType="submit">
                          立即查询
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </Card>
    </div>
  )
}

export default SearchFilter;
