import { Component } from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Layout, Modal, message, Skeleton } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CgSider from "./components/cg-sider/cg-sider";
import CgBreadCrumb from "./components/cg-bread-crumb/cg-bread-crumb";
import CgContent from "./components/cg-content/cg-content";
import "./layout.scss";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as userDetailsActions from "@/store/actions/userDetails";
import * as userPermissionsActions from "@/store/actions/userPermissions";
import * as switcherActions from "@/store/actions/switcher";

import { isMobileDevice } from "@/utils/common";
import { handleRefreshSession, doLogout } from "@/utils";
import { getTermsConditionApi } from "@/api/user";
import { termsAndConditionsConfig } from "@/pages/termsConditions/constant";

const { confirm } = Modal;

interface Props extends RouteComponentProps {
  userDetailsData: any;
  userPermissionsData: {};
  switcherData: {};
  getUserDetails: any;
  getUserPermissions: any;
  getSwitcherInfo: any;
}

interface State {
  globalDataIsReady: boolean;
  collapsed: boolean;
  footerInfo: object;
  isMouseMove: boolean;
}

class MyLayout extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.renderCgSideMenus = this.renderCgSideMenus.bind(this);
  }

  public state = {
    globalDataIsReady: false,
    collapsed: isMobileDevice(),
    footerInfo: {
      items: [
        { linkUrl: "", linkOpenCriteria: "", linkName: "© 2023 Cargill, Incorporated. 保留所有权利" },
        { linkUrl: "https://beian.miit.gov.cn/", linkOpenCriteria: "_blank", linkName: "沪ICP备15006728号-4" },
        { linkUrl: "https://www.cargill.com/privacy", linkOpenCriteria: "_blank", linkName: "隐私政策" },
        {
          linkUrl: "https://www.cargill.com/page/business-notice-zh",
          linkOpenCriteria: "_blank",
          linkName: "业务信息声明"
        },
        { linkUrl: "/termsconditions?type=view", linkOpenCriteria: "_self", linkName: "使用条款" },
        { linkUrl: "/crossbordertransfernotice", linkOpenCriteria: "_self", linkName: "个人信息出境告知书"}
      ],
      items_right: [
        { linkUrl: "", linkOpenCriteria: "", linkName: `当前版本 V ${process.env.REACT_APP_VERSION}` }
      ]
    },
    isMouseMove: false
  };

  logoutInterval: any;
  timeCount: number = 0;

  componentDidMount() {
    // 初始化全局基础信息
    this.props.getUserDetails();
    this.props.getUserPermissions();
    this.props.getSwitcherInfo();
    // token的监听与刷新
    handleRefreshSession();
    // 鼠标移动监听判断是否提醒客户登录即将过期
    this.handleMouseMove();
    this.handleLogoutNotify();
  }

  componentDidUpdate(prevProps: any) {
    const { userDetailsData } = this.props;
    if (
      userDetailsData &&
      Object.keys(userDetailsData).length > 0 &&
      userDetailsData.profile.email &&
      prevProps.userDetailsData !== userDetailsData
    ) {
      this.doCheckUserTermsAndConditions();
    }
  }

  componentWillUnmount() {
    this.clearLogoutInterval();
    this.setState = () => false;
  }

  // 鼠标移动监听函数--如果移动则将计时器的 timeCount 置为0
  handleMouseMove = () => {
    let mouseX: number;
    let mouseY: number;
    window.addEventListener("mousemove", event => {
      const x1 = event.clientX;
      const y1 = event.clientY;
      if (mouseX !== x1 || mouseY !== y1) {
        this.timeCount = 0;
      }
      mouseX = x1;
      mouseY = y1;
    });
  };

  // 5分钟监听判断一次鼠标位置的变化
  handleLogoutNotify() {
    this.logoutInterval = setInterval(() => {
      this.timeCount += 5;
      if (this.timeCount === 25) {
        const logoutTimeFormat = moment(new Date(new Date().getTime() + 300000)).format("YYYY-MM-DD HH:mm:ss");
        confirm({
          title: "登录即将过期提示",
          icon: <ExclamationCircleOutlined />,
          content: `您的登录状态将在 ${logoutTimeFormat} 之后失效，如需继续使用本系统，请点击 “确认”， 否则点击 “取消” 退出系统。如超过时限未确认，您将自动退出系统`,
          onOk: () => {
            this.timeCount = 0;
          },
          onCancel: () => {
            doLogout();
          }
        });
      }
      if (this.timeCount === 30) {
        doLogout();
      }
    }, 300000);
  }

  clearLogoutInterval = () => {
    if (this.logoutInterval) {
      clearInterval(this.logoutInterval);
    }
  };

  // 确认用户是否需要跳转隐私页面进行同意操作
  doCheckUserTermsAndConditions = () => {
    const { userDetailsData } = this.props;
    if (userDetailsData && userDetailsData?.profile) {
      getTermsConditionApi(userDetailsData.profile.email)
        .then((responseJSON: any) => {
          if (responseJSON.code && responseJSON.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
          } else if (responseJSON?.data) {
            //跳转到隐私政策页面
            if (
              !responseJSON.data.tandC ||
              this.checkSectionState(
                responseJSON.data.tncAcceptedDate,
                termsAndConditionsConfig.data.updateDateTimeContent
              )
            ) {
              this.props.history.push("/termsconditions");
            }
          }
        })
        .catch((exception: any) => {
          message.error("获取数据失败，请稍后重试。");
          console.log(exception);
        });
    }
  };

  checkSectionState = (tncDate: any, configContent: any) => {
    // 为 tncDate 添加 8 小时
    const tncDateWithOffset = new Date(new Date(tncDate).getTime() + 8 * 60 * 60 * 1000);
    const tncChanged = configContent.filter((obj: any) => new Date(obj.date) > tncDateWithOffset);
    return tncChanged.length > 0;
  };

  globalDataIsReady = () => {
    const { userDetailsData, userPermissionsData, switcherData } = this.props;
    return (
      Object.keys(userDetailsData).length > 0 &&
      Object.keys(userPermissionsData).length > 0 &&
      Object.keys(switcherData).length > 0
    );
  };

  toggleCollapsed = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed
    });
  };

  renderCgSideMenus(props: any) {
    const { collapsed } = this.state;
    return <CgSider {...props} collapsedStatus={collapsed} />;
  }

  public render() {
    const { collapsed, footerInfo } = this.state;
    return (
      <div id="main" className="hdp-uf main-page-content">
        {this.globalDataIsReady() ? (
          <Layout style={{ minHeight: "100vh" }}>
            <Layout>
              {/* 侧栏 */}
              {/* <Route render={props => <CgSider {...props} collapsedStatus={collapsed} />} /> */}
              <Route render={this.renderCgSideMenus} />

              <Layout className={`${collapsed ? "main-content-collapsed" : "main-content"}`}>
                {/* 内容部分 */}
                <CgBreadCrumb collapsedStatus={collapsed} toggleCollapsed={this.toggleCollapsed} />
                <CgContent />
                {/* 底部Footer展示区域 */}
                <div className="main-footer">
                  <div className="hdp-uf hdp-uf-vc hdp-uf-hsb footer-container">
                    <ul className="hdp-uf hdp-uf-vc footer-left">
                      {footerInfo.items.map((item: any) => {
                        return (
                          <li className="link-item" key={`footer-link-item-${item.linkName}`}>
                            {item.linkUrl ? (
                              <a href={item.linkUrl} target={item.linkOpenCriteria || "_blank"}>
                                {item.linkName}
                              </a>
                            ) : (
                              <span>{item.linkName}</span>
                            )}
                          </li>
                        );
                      })}
                      {/* TRUST ARC TAG */}
                      <li className="link-item">
                        <div id="teconsent" className="footer-consent-link"></div>
                      </li>
                      <li className="link-item">
                        <div id="trustarc-irm-placeholder" className="footer-consent-link"></div>
                      </li>
                      <li className="link-item">
                        <div id="consent_blackbar" className="cookie-consent-banner"></div>
                      </li>
                    </ul>
                    <div className="footer-right">
                      <ul className="hdp-uf">
                        {footerInfo.items_right.map((item: any) => {
                          return (
                            <li className="link-item" key={`footer-link-item-${item.linkName}`}>
                              <span>{item.linkName}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </Layout>
            </Layout>
          </Layout>
        ) : (
          <div className="hdp-uf hdp-skeleton">
            <div className="hdp-uf hdp-uf-vc hdp-uf-dc skeleton-side-bar">
              <Skeleton.Avatar active size="large" />
              <Skeleton active paragraph={{ rows: 16 }} />
            </div>
            <div className="hdp-uf hdp-uf-dc skeleton-content">
              <div className="skeleton-content-header">
                <Skeleton.Button active size="large" block />
              </div>
              <div className="skeleton-content-paragraph">
                <Skeleton active paragraph={{ rows: 10 }} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userDetailsData: state.userDetails,
  userPermissionsData: state.userPermissions,
  switcherData: state.switcher
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getUserDetails: () => {
    dispatch(userDetailsActions.getUserDetailsData());
  },
  getUserPermissions: () => {
    dispatch(userPermissionsActions.getUserPermissionsData());
  },
  getSwitcherInfo: () => {
    dispatch(switcherActions.getSwitcherData());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLayout);
