import { allOccBusinessLine, allSupplierMutiple } from "@/utils/constant";
import { getFirmDetailsList } from "@/api/constant";
import { Button, Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import RemoteSelect from "@/pages/common/remoteSelect";
import { getCode, noRepeat } from "@/utils/common";
import { cutHeaderZero } from "@/utils";
import moment from "moment";
import { getFirmDetailListApi } from "@/api/availableBalance";
import { getProductDetailList } from "@/api/contractReport";

// 定义函数组件的props相关参数
interface myProps {
  switcher: any,
  userDetails: any,
  permissions: any,
  onSearch: (arg0: any) => void;
};

const SearchFilter = (props: myProps) => {
  const { switcher, userDetails, permissions } = props;
  const [isSearchLoading, setIsSelectFetching] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [searchValue, setSearchValue] = useState({} as any);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values?: any) => {
      const valuesTemp = { ...values };
      valuesTemp.customerCode = getCode(valuesTemp.customerCode);
      valuesTemp.productCode = getCode(valuesTemp.productCode);
      valuesTemp.dateTime = valuesTemp.dateTime ? moment(valuesTemp.dateTime).format("YYYY-MM-DDTHH:mm") : "";
      props.onSearch(valuesTemp);
      setSearchValue(valuesTemp);
    },
    [props]
  );

  const onValuesChange = useCallback((changeValue: any, allValues: any) => {
    const valuesTemp = { ...allValues };
    valuesTemp.customerCode = getCode(valuesTemp.customerCode);
    valuesTemp.productCode = getCode(valuesTemp.productCode);
    valuesTemp.dateTime = valuesTemp.dateTime ? moment(valuesTemp.dateTime).format("YYYY-MM-DDTHH:mm") : "";
    setSearchValue(valuesTemp);
  }, [])

  // 外部Group用户筛选公司
  const onFilterOption = useCallback((inputValue: any, option: any) => {
    return ((option?.label as unknown) as string).toLowerCase().includes(inputValue?.toLowerCase());
  }, []);

  // 外部 group 用户查询公司（集团公司）
  const fetchCustomerOptions_External = useCallback((controlIdentifier: any) => {
    getFirmDetailsList({ accountNumber: controlIdentifier })
      .then((res: any) => {
        if (res.data && res.data.customerInfoEntitlement?.length > 0) {
          let options = res.data.customerInfoEntitlement.map((val: any) => {
            return {
              label: `${val.accountLongName}(${val.accountNumber})`,
              value: `${val.accountLongName}(${val.accountNumber})`,
              key: val.accountNumber,
              name: val.accountLongName
            };
          });
          const currentCustomerOption = options.find((val: any) => val.key === switcher.customerCode);
          setCustomerOptions(options);
          form.setFieldsValue({
            customerCode: currentCustomerOption?.value
          });
        } else {
          setCustomerOptions([]);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      });
  }, [form, switcher]);

  // 内部用户--获取买方选项(防抖输入查询)
  const fetchCustomerOptions_Internal = useCallback(
    (value: string | undefined, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getFirmDetailListApi({
        orgCode: searchValue.orgCode || "",
        customerName: value || ""
      })
        .then((res: any) => {
          if (res?.code === "99999") {
            // 99999 提示后端的错误信息
            callback([]);
            message.error(res.errorMsg);
          } else if (res.data?.customerList?.length > 0 && currentValue === value) {
            let data: any[] = res.data?.customerList?.map((val: any) => {
              return {
                label: val.customerName + "(" + val.customerCode + ")",
                value: val.customerName + "(" + val.customerCode + ")",
                key: val.customerCode
              };
            });
            callback(data);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [searchValue.orgCode]
  );

  // 获取产品名称选项
  const fetchProductNameOptions = useCallback(
    (value: string, callback: Function, currentValue: string | undefined) => {
      setIsSelectFetching(true);
      getProductDetailList({
        productName: value,
        salesOrgs: searchValue.orgCode
      })
        .then((responseJSON: any) => {
          if (responseJSON?.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(responseJSON.errorMsg);
            callback([]);
          } else if (responseJSON?.data?.products.length > 0 && currentValue === value) {
            let NewData = [];
            if (permissions.CNExternalUsers) {
              NewData = responseJSON.data.products.map((item: any) => ({
                label: `${item.productName}-${item.ctsCode}`,
                value: cutHeaderZero(item.productCode)
              }));
              NewData = noRepeat(NewData);
            } else {
              NewData = responseJSON.data.products.map((item: any) => ({
                label: `${item.productName} (${cutHeaderZero(item.productCode)})`,
                value: cutHeaderZero(item.productCode)
              }));
            }
            callback(NewData);
          } else {
            callback([]);
          }
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          setIsSelectFetching(false);
        });
    },
    [permissions.CNExternalUsers, searchValue.orgCode]);

  const initExternalFieldsValue = useCallback(() => {
    if (permissions.CNExternalUsers) {
      // 判断是否为集团用户
      const userDataCheck = userDetails && Object.keys(userDetails).length && userDetails.entitlements;
      if (userDataCheck) {
        const { entitlements } = userDetails;
        const {
          otherControls: { companyAccountType },
          controlIdentifier
        } = entitlements[0];
        if (companyAccountType === "GROUP") {
          fetchCustomerOptions_External(controlIdentifier);
        } else {
          form.setFieldsValue({ customerCode: switcher.customerName + "(" + switcher.customerCode + ")" });
        }
      }
    }
  }, [form, switcher, permissions, userDetails, fetchCustomerOptions_External]);

  useEffect(() => {
    if (switcher) {
      form.setFieldsValue({
        orgCode: switcher.orgCode === "All" ? null : switcher.orgCode
      });
      initExternalFieldsValue();
      setSearchValue({
        orgCode: switcher.orgCode === "All" ? null : switcher.orgCode,
        customerCode: null,
        productCode: ""
      })
    }
  }, [form, switcher, initExternalFieldsValue]);


  return (
    <div className="search-filter">
      <div className="base-filter">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            orgCode: null,
            businessLine: null,
            customerCode: null,
            dateTime: null
          }}
        >
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col md={4} xs={24}>
              <Form.Item label="卖方" name="orgCode" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }} placeholder="请选择" options={allSupplierMutiple()} />
              </Form.Item>
            </Col>

            {permissions.CNInternalUsers && (
              <Col md={4} xs={24}>
                <Form.Item
                  label="公司名称"
                  name="customerCode"
                  rules={[{ required: true }]}
                >
                  <RemoteSelect
                    placeholder="请输入名称查询"
                    fetchOptions={fetchCustomerOptions_Internal}
                    loading={isSearchLoading}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && userDetails?.isGroup && (
              <Col md={4} xs={24}>
                <Form.Item label="公司名称" name="customerCode" rules={[{ required: true }]}>
                  <Select
                    showSearch
                    placeholder="请选择公司名称"
                    options={customerOptions}
                    optionFilterProp="label"
                    filterOption={onFilterOption}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}
            {permissions.CNExternalUsers && !userDetails?.isGroup && (
              <Col md={4} xs={24}>
                <Form.Item label="公司名称" name="customerCode" rules={[{ required: true }]}>
                  <Input disabled />
                </Form.Item>
              </Col>
            )}
            <Col md={4} xs={24}>
              <Form.Item label="业务类型" name="businessLine" rules={[{ required: true }]}>
                <Select style={{ width: "100%" }} placeholder="请选择" options={permissions.CNExternalUsers ? allOccBusinessLine(["增值油/包装油业务"]) : allOccBusinessLine()} />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item label="日期时间" name="dateTime" rules={[{ required: true }]}>
                <DatePicker format="YYYY-MM-DD HH:mm" showTime style={{ width: "100%" }} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item label="产品" name="productCode" rules={[{ required: true }]}>
                <RemoteSelect
                  dataTestId="productCode"
                  placeholder="请输入产品名称查询"
                  fetchOptions={fetchProductNameOptions}
                  loading={isSearchLoading}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item>
                <Button
                  style={{ marginTop: 30 }}
                  type="primary"
                  className="submit-btn"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  查询
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div >
  );
};

const mapStateToProps = (state: any) => ({
  switcher: state.switcher,
  userDetails: state.userDetails,
  permissions: state.userPermissions
});

export default connect(mapStateToProps, null)(SearchFilter);