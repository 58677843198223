import moment from "moment";
import { isHaveThisPermission } from "./common";

const formatDateForUi = (...date: any) => {
  if (date.includes("") || date.includes(null)) return "";
  const dateFormat = "DD-MMM-YYYY";
  const dateVal = date.slice(0, 3).reduce((con: string, item: string) => con + (con ? `-${item}` : item), "");
  return moment(dateVal).format(dateFormat);
};

const formatDateForAPI = (...date: any) => {
  if (date.includes("") || date.includes(null)) return "";
  const dateFormat = "YYYY-MM-DD";
  const dateVal = date.slice(0, 3).reduce((con: string, item: string) => con + (con ? `-${item}` : item), "");
  return moment(dateVal).format(dateFormat);
};

const getNotNullValue = (value: any) => {
  if (value || value === "0" || value === 0) {
    return true;
  }
  return false;
};

// content-disposition: attachment; filename=INV_20210813_103915_UnLoad.xls
const getFilename = (headers: any) => {
  if (!headers) {
    console.log("responseHeader", headers);
    return "";
  }
  const contentDisposition = headers["content-disposition"];
  console.log("content-disposition", contentDisposition);
  if (!contentDisposition) {
    return "";
  }
  if (contentDisposition.indexOf("filename=") === -1) {
    return "";
  }
  const filename = contentDisposition.split("=").pop();
  console.log("--------filename", filename);
  return filename;
};

const convertUTCDateToChinaDate = (date: any) => {
  function z(n: number) {
    return (n < 10 ? "0" : "") + n;
  }
  const d = new Date(`${date}Z`);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset() + 480);
  const time = `${z(d.getHours())}:${z(d.getMinutes())}`;
  return { date: d, time };
};

const convertUTCDateToChinaDateBySeconds = (date: any) => {
  function z(n: number) {
    return (n < 10 ? "0" : "") + n;
  }
  const d = new Date(`${date}Z`);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
  const time = `${z(d.getHours())}:${z(d.getMinutes())}:${z(d.getSeconds())}`;
  return { date: d, time };
};

const convertUTCDateToChinaDateAR = (date: any) => {
  function z(n: number) {
    return (n < 10 ? "0" : "") + n;
  }
  const d = new Date(`${date}Z`);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
  const time = `${z(d.getHours())}:${z(d.getMinutes())}`;
  return { date: d, time };
};

const convertUTCDateToChinaDateARBySeconds = (date: any) => {
  function z(n: number) {
    return (n < 10 ? "0" : "") + n;
  }
  const d = new Date(`${date}Z`);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
  const time = `${z(d.getHours())}:${z(d.getMinutes())}:${z(d.getSeconds())}`;
  return { date: d, time };
};

const noSpecialCharacter = (value: any) => {
  const regex = new RegExp("^[a-zA-Z0-9 ]*$");
  return regex.test(value);
};

const validateChineseAndAlphanumericChar = (value: any) => {
  // const regex = new RegExp(/[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFFa-zA-Z0-9]+/g);
  const regex = new RegExp(/[^\u4E00-\u9FA5a-zA-Z0-9\\(\\)（）]+/g); // 中文/英文/数字/中英文括号
  return !regex.test(value);
};

// 用于合同行项目编号去掉前面的0
const cutBatchNoZero = (data: string) => {
  if (data) {
    const interceptStr = data.substring(data.length - 6);
    const reg = /\b(0+)/gi;
    return interceptStr.replace(reg, "");
  }
  return data;
};

// 嘉吉结算单列表合同号显示
const contractNoDisplay = (data: any) => {
  if (data) {
    const wsReg = /GOCCHN-/g;
    const wsMatchStr = data.match(wsReg);
    if (wsMatchStr && wsMatchStr[0].length > 0) {
      return data.originContractNo.replace(/GOCCHN-/g, "");
    }

    const reg = /_.\d*/gi;
    const noList = data.split(",");
    if (noList && noList.length <= 1) {
      const matchStr = data.match(reg);
      if (matchStr && matchStr[0].length >= 7) {
        // 长度大于等于6的
        const interceptStr = matchStr[0].substring(matchStr[0].length - 6).replace(/^0*/gi, "");
        return data.replace(reg, `_${interceptStr}`);
      }
      // 长度小于6的
      const interceptStr = matchStr[0].substring(1).replace(/^0*/gi, "");
      return data.replace(reg, `_${interceptStr}`);
    } else if (noList && noList.length > 1) {
      const lastList = [];
      for (let i = 0; i < noList.length; i += 1) {
        const matchStr = noList[i].match(reg);
        if (matchStr && matchStr[0].length >= 7) {
          // 长度大于等于6的
          const interceptStr = matchStr[0].substring(matchStr[0].length - 6).replace(/^0*/gi, "");
          // return data.replace(reg, `_${interceptStr}`);
          lastList.push(noList[i].replace(reg, `_${interceptStr}`));
        } else if (matchStr && matchStr[0].length < 7) {
          // 长度小于6的
          const interceptStr = matchStr[0].substring(1).replace(/^0*/gi, "");
          // return data.replace(reg, `_${interceptStr}`);
          lastList.push(noList[i].replace(reg, `_${interceptStr}`));
        }
      }
      return lastList.toString();
    }
  }
  return data;
};

// 去掉字符串前面全部的0
const cutHeaderZero = (data: string) => {
  if (data) {
    const reg = /\b(0+)/gi;
    return data.replace(reg, "");
  }
  return data;
};

// 合同行项目编号传至后端添0
const addZeroToBatch = (data: string | any[]) => {
  if (data) {
    let zeroNum = "";
    for (let i = 0; i < 6 - data.length; i += 1) {
      zeroNum += "0";
    }
    return zeroNum + data;
  }
  return data;
};

// 设置买方发货地址的显示
const setShipAddressDesc = (data: any) => {
  if (data && data.shipAddressDesc && data.shipAddress && isHaveThisPermission("CNInternalUsers")) {
    return `${data.shipAddress} (${data.shipAddressDesc})`;
  }
  return data.shipAddressDesc;
};

// 后端判断是否为外库配送船
const isDeliveryByShip = (data: any) => data && data.dscTransportType === "ACM外江码头";

// 前端判断是否为ACM外库配送船
const isExternalDeliveryByShip = (data: any) => {
  if (data.buyerSellerOption === "S" && data.orgCode === "1006") {
    if (["11", "12", "22", "23"].indexOf(data.transportType) !== -1 && data.shipAddress.slice(0, 2) === "9B") {
      return true;
    }
  }
  return false;
};

const getProductNameInitValue = (data: any) => {
  if (data) {
    if (data.businessLine === "包装油业务") {
      return data.productName;
    }
    if (isHaveThisPermission("CNInternalUsers")) {
      return data.productName;
    } else {
      return `${data.materialSalesText?.trim() || "-"}${data.packageSpecificationCn ? "_" + data.packageSpecificationCn.trim() : ""
        }`;
    }
  }
  return "-";
};

const getFilterData = (data: any, values: any) => {
  if (values) {
    const newData = data.filter((val: any) => {
      const label = val.label?.toLowerCase();
      const value = values?.toLowerCase();
      return label.includes(value);
    });
    return newData;
  }
  return data;
};

const expandDateDisplay = (date: moment.MomentInput, isDelayed: any) => {
  if (date) {
    if (isHaveThisPermission("CNInternalUsers")) {
      return moment(date).format("YYYY-MM-DD");
    } else if (isHaveThisPermission("CNExternalUsers")) {
      return isDelayed ? moment(date).format("YYYY-MM-DD") : "-";
    }
  }
  return "-";
};

const expandDelayDateDisplay = (date: moment.MomentInput) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  }
  return "-";
};

const connectMaterialTextAndSpec = (materialText: any, specText: any) => {
  if (specText) {
    return `${materialText || "-"}_${specText}`;
  }
  return materialText || "-";
};

// 是否包含谷物油籽业务
const hasCrushContract = (list: any) => {
  if (Array.isArray(list)) {
    const crushList = list.filter(item => parseInt(item.divisionCode) === 40);
    return crushList.length > 0;
  }
  return false;
};

// 处理嘉吉原合同号 GO6-GTIS-40379-01 | 1111111_000000020
const getOldContractNumber = (data: string) => {
  const numberArr = data.split(",");
  const newData = numberArr.map((val: any) => {
    const valArr = val.split("_");
    return valArr.length > 1 ? valArr[0] + "_" + valArr[1]?.replace(/\b(0+)/gi, "") : val;
  });
  return newData.join(",");
};

// 判断两个时间相差超过指定小时数
const compareDate = (startDateTime: string, endDateTime: string, hourNum: number) => {
  if (!startDateTime || !endDateTime || !hourNum) {
    return undefined;
  }

  const firstTime = new Date(startDateTime);
  const secondTime = new Date(endDateTime);
  const diffInMs = Math.abs(firstTime.getTime() - secondTime.getTime());
  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours > hourNum;
};

const isNumber = (value: any) => /^\d*$/.test(value);

const isMaxLength = (value: any, length: number) => value.toString().length > length;

const isLengthEqual = (value: any, length: number) => value.toString().length === length;

// 去掉前后或者所有的空格
const formatStrTrim = (str: string, is_global: boolean = false) => {
  if (typeof str === "string") {
    let res = str.replace(/(^\s*)|(\s*$)/g, "");
    if (is_global) {
      res = res.replace(/\s/g, "");
    }
    return res;
  }
  return str;
};

// 将字符串中所有的小写字符转换为大写字母
const handleToUpperCase = (str: string) => {
  // 验证英文字母只能为大写字母
  if (str) {
    const reg = /^.*[a-z]+.*$/g;
    if (reg.test(str)) {
      str = str.toUpperCase();
    }
  }
  return str;
};

const formatValue = (value: any, replaceStr: any = "") => {
  return value || replaceStr;
};

const formatNumberValue = (value: any) => value ?? 0;

const formatDateTimeToStr = (dateValue: string, formatText: string, replaceStr?: string) => {
  if (dateValue) {
    return moment(dateValue).format(formatText);
  }
  return replaceStr || "-";
};

// 将fileKeys里面的用逗号分隔的字符串转换为单个的数组元素，并组成新的数组
const formatFileKeys = (fileKeys: string[]) => {
  const fileKeysNew: string[] = [];
  fileKeys.forEach((item: any) => {
    if (item) {
      const arr = item.split(",");
      fileKeysNew.push(...arr);
    }
  });
  return fileKeysNew;
};

// 去掉字符串首尾的双引号
const removeStrQuotes = (str: string) => {
   return str ? str.replace(/^"|"$/g, '') : "";
};

// 顺序排序规则
const numericSort = (a: any, b: any) => {
  const numA = Number(a);
  const numB = Number(b);
  return numA - numB;
};

// 将身份证号最后一位的'X'替换为'0'
const replaceIdCardLastChar = (idCard: string): string => {
  if (!idCard) {
    return "";
  }
  return idCard.endsWith('X') ? idCard.slice(0, -1) + '0' : idCard;
};

export {
  formatDateForUi,
  formatDateForAPI,
  noSpecialCharacter,
  validateChineseAndAlphanumericChar,
  compareDate,
  isNumber,
  isMaxLength,
  isLengthEqual,
  convertUTCDateToChinaDate,
  convertUTCDateToChinaDateAR,
  convertUTCDateToChinaDateBySeconds,
  convertUTCDateToChinaDateARBySeconds,
  cutBatchNoZero,
  expandDateDisplay,
  expandDelayDateDisplay,
  connectMaterialTextAndSpec,
  hasCrushContract,
  setShipAddressDesc,
  addZeroToBatch,
  cutHeaderZero,
  isDeliveryByShip,
  isExternalDeliveryByShip,
  getProductNameInitValue,
  getFilterData,
  getNotNullValue,
  contractNoDisplay,
  getFilename,
  getOldContractNumber,
  formatStrTrim,
  handleToUpperCase,
  formatValue,
  formatDateTimeToStr,
  formatFileKeys,
  removeStrQuotes,
  formatNumberValue,
  numericSort,
  replaceIdCardLastChar
};
