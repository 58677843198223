import { useState, useCallback } from "react";
import { Modal, Button, Input, Form, message, Row, Col } from "antd";
import { rejectContract } from "@/api/directSys";
const { TextArea } = Input;

const RefuseModal = (props: any) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleOk = useCallback(
    (values: any) => {
      setLoading(true);
      const request: any = [];
      props.selectedRow.forEach((e: any) => {
        request.push({ id: e.id, reason: values.reason });
      });
      rejectContract(request, {})
        .then((res: any) => {
          if (res?.result) {
            props.getListData();
          } else {
            message.error(res?.data?.description);
          }
        })
        .finally(() => {
          setIsModalVisible(false);
          setLoading(false);
          setIsEmpty(true);
          form.resetFields();
        });
    },
    [form, props]
  );

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    form.resetFields();
    setIsEmpty(true);
  }, [form]);

  const handleVisible = useCallback(() => {
    setIsModalVisible(!isModalVisible);
  }, [isModalVisible]);

  const handleTextChange = useCallback(() => {
    if (!form.getFieldValue("reason")) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [form]);

  // // systemSource === '温氏' return true
  // // systemSource === '通威' || (systemSource === '正大' && 执行方式为 变更，状态为 变更中，拒绝按钮点亮) return fasle
  const buttonDisabled = () => {
    for (const item of props.selectedRow) {
      if ((["温氏", "牧原"].includes(item.systemSource) && ["已创建", "已变更", "确认终止"].includes(item.contractState)) || 
          (item.systemSource === "正大" && (item.contractState !== "变更中" || item.executeWay !== "变更"))
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {props.fitContent ? (
        <Button
          type="primary"
          onClick={handleVisible}
          disabled={buttonDisabled() || !props.isSelected}
          style={{ width: "fit-content" }}
        >
          拒绝
        </Button>
      ) : (
        <Button
          type={props.isText ? "text" : "primary"}
          onClick={handleVisible}
          disabled={buttonDisabled() || !props.isSelected}
        >
          拒绝
        </Button>
      )}
      <Modal title="拒绝原因" maskClosable={false} visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <Form form={form} onFinish={handleOk}>
          <Form.Item name="reason">
            <TextArea data-testid="test-textarea" placeholder="请填写拒绝原因" onChange={handleTextChange} />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col style={{ marginLeft: "auto", marginRight: 0 }}>
              <Form.Item>
                <div style={{ marginTop: 0 }}>
                  <Button type="primary" style={{ backgroundColor: "#5c5c5c" }} onClick={handleCancel}>
                    取消
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    htmlType="submit"
                    data-testid="test-submit"
                    disabled={isEmpty}
                    loading={loading}
                  >
                    提交
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default RefuseModal;
