import React, { useState } from 'react';
import { Modal, Upload, message, Button, Input, Form, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './style.scss';

interface Props {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onUpload: (fileList: any) => Promise<void>;
}

const UploadModal: React.FC<Props> = (props) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const { visible, loading, onClose, onUpload } = props;

  // 25MB 的字节数
  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const handleOk = async () => {
    try {
      if (fileList.length > 0) {
        // 将数据封装成对象
        const uploadData = {
          files: fileList
        };
        await onUpload(uploadData);
        handleCancel();
      } else {
        message.warning('请先选择要上传的文件');
      }
    } catch (error) {
      console.error('表单验证失败:', error);
    }
  };

  const handleCancel = () => {
    setFileList([]);
    form.resetFields();
    onClose();
  };

    // 提取验证逻辑到单独的函数
    const validateFile = (file: any): boolean => {
      if (file.type !== 'application/pdf') {
        message.error('只能上传 PDF 文件！');
        return false;
      }
  
      if (file.size > MAX_FILE_SIZE) {
        message.error('文件超过最大限制5M，请线下联系对应业务同事手动上传');
        return false;
      }
  
      return true;
    };

    const uploadProps = {
      accept: '.pdf',
      beforeUpload: (file: any) => {
        const isValid = validateFile(file);
        if (isValid) {
          setFileList([file]);
          form.setFieldsValue({ fileName: file.name });
        }
        return false;  // 保持手动上传模式
      },
      fileList,
      onRemove: () => {
        setFileList([]);
        form.setFieldsValue({ fileName: '' });
      },
      maxCount: 1,
      showUploadList: false
    };

  // 在 Modal 关闭时销毁内容
  const afterClose = () => {
    setFileList([]);
    form.resetFields();
  };

  return (
    <Modal
      title="文档上传"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      afterClose={afterClose}
      okText="确定"
      cancelText="取消"
      width={520}
      className="custom-upload-modal"
      closeIcon={<span className="modal-close">×</span>}
      destroyOnClose={true}
    >
      <Spin spinning={loading} tip="文件上传中...">
        <Form form={form} initialValues={{ signed: true, fileName: '' }}>
          <div className="upload-content">
            <div className="upload-input-group">
              <Upload {...uploadProps}>
                <Button className="add-attachment-btn">
                  <UploadOutlined /> 添加附件
                </Button>
              </Upload>
              <Form.Item name="fileName" rules={[{ required: true, message: "请添加附件" }]} style={{ flex: 1, marginBottom: 0 }}>
                <Input
                  className="file-path-input"
                  readOnly
                  placeholder="XXXXXXXXXXXXXXXX"
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UploadModal; 